<template>
    <div :class="CSSClasses" class="expert-highlights-block">
        <div class="experts">
            <div class="expert" v-for="expert in expertHighlightsData" :key="expert.person?.id">
                <div class="expert-lockup">
                    <div class="person-titles">
                        <Anchor :url="expert.person?.path + '?lang=' + pageLanguage" class="link">
                            <Typography class="person-name" as="p" variant="h5-display">
                                {{ getPersonFullName(expert.person) }}
                            </Typography>
                        </Anchor>
                        <Typography
                            v-if="expert.person?.personTitle"
                            class="person-title"
                            as="p"
                            variant="body-display-micro"
                        >
                            {{ expert.person?.personTitle }}
                        </Typography>
                    </div>
                    <Avatar
                        class="expert-avatar"
                        v-if="expert.person?.avatar"
                        :image="expert.person?.avatar"
                        :url="expert.person?.url + '?lang=' + pageLanguage"
                        size="medium"
                    />
                </div>
                <TagCluster class="tag-cluster" :items="expert.tags" :pageLanguage="pageLanguage" />
                <ContentSummary
                    v-if="expert.featuredContent"
                    :theme="theme"
                    :label="$t(expert.featuredContent?.label)"
                    :people="expert.featuredContent?.people"
                    :url="expert.featuredContent?.url"
                    :mediaSource="expert.featuredContent?.mediaSource"
                    :isDekHidden="true"
                    :syndications="expert.featuredContent?.syndications"
                    :pageLanguage="pageLanguage"
                >
                    <template #title>
                        <Typography v-if="expert.featuredContent?.title" as="div" variant="body-large" class="heading">
                            {{ expert.featuredContent?.title }}
                        </Typography>
                    </template>
                </ContentSummary>
            </div>
        </div>
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    experts: {
        type: Array,
    },
    showExpertTags: {
        type: Boolean,
    },
});
const theme = inject('tierTheme', 'light');
const CSSClasses = computed(() => {
    return [`theme-${theme}`];
});
const expertHighlightsData = computed(() =>
    props.experts?.map((expert) => {
        const contentFeedItems = getContentFeedItems(expert.featuredContent.feed, expert.featuredContent.dynamic);
        const contentType = contentFeedItems[0]?.contentType;
        let featuredContent;
        if (contentType) {
            featuredContent = getContentTypeParsers(contentFeedItems[0], contentFeedItems[0].overrideImage)[
                contentType
            ]?.();
        }
        const tags = expert.person.personDetails.featuredTopicsAndRegions
            ?.filter((tag) => tag.isPrimary)
            .map((tag) => {
                return {
                    id: tag.id,
                    ...tag.tag.value,
                };
            });
        return {
            featuredContent,
            tags,
            person: expert.person,
        };
    }),
);
</script>

<style lang="scss" scoped>
.expert-highlights-block {
    .expert {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        padding: 3rem 0;
        border-bottom: 1px solid palette(border-light);
        @include media-query(phone-mw) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2.4rem;
        }
        @include media-query(tablet-mw) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
    .expert-lockup {
        display: flex;
        justify-content: space-between;
        gap: 1.6rem;
        @include media-query(phone-mw) {
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    .tag-cluster {
        display: none;
        @include media-query(tablet-mw) {
            display: flex;
            align-self: center;
        }
    }
    .person-name {
        color: color(blue, dark, 0.8);
    }
    .person-title {
        color: color(noir, default, 0.6);
    }
    &.theme-dark {
        .person-name {
            color: color(blanc, default, 0.8);
        }
        .person-title {
            color: color(blanc, default, 0.6);
        }
    }
}
</style>
